<template>
  <gli-dialog
    width="700"
    :title="isNewTag ? $t('Add new tag') : $t('Edit tag')"
    :isNew="isNewTag"
    :editedObject="editedTag"
    @delete="deleteTag(editedTag._id)"
    @save="save"
    @cancel="cancelEditingTag"
  >
    <v-row dense>
      <v-col cols="12">
        <gli-text-field
          v-model="name"
          :label="$t('Name')"
          required
          :rules="[rules.required]"
          :autofocus="isNewTag"
        ></gli-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          type="number"
          v-model.number="base"
          :label="$t('Extrusion base')"
          required
          min="0"
          :max="top ? top : 100"
          step="0.1"
          suffix="m"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          type="number"
          v-model.number="top"
          :label="$t('Extrusion top')"
          required
          :min="base ? base : 0"
          max="100"
          step="0.1"
          suffix="m"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
    </v-row>
  </gli-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import rules from '../mixins/rules';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'TagDialog',

  data() {
    return {};
  },
  computed: {
    ...mapGetters('organization', ['isNewTag', 'editedTag']),
    ...mapFields('organization', ['editedTag.name', 'editedTag.base', 'editedTag.top'])
  },

  methods: {
    ...mapActions('organization', ['saveTag', 'cancelEditingTag', 'deleteTag']),
    ...mapActions('alert', ['error']),
    save() {
      if (this.base >= 0 && this.top <= 100 && this.top >= this.base) {
        this.saveTag();
      } else {
        this.error('Invalid extrusion values!');
      }
    }
  },
  mixins: [rules]
};
</script>
