let methods = {
  resizeTable(tableId) {
    this.$nextTick(() => {
      const idSelector = typeof tableId === 'string' ? `#${tableId}` : '';
      const query = `div.v-data-table${idSelector}>div.v-data-table__wrapper`;
      const dataTableWrapper = document.querySelector(query);

      if (dataTableWrapper) {
        const distanceFromTop = window.pageYOffset + dataTableWrapper.getBoundingClientRect().top;

        dataTableWrapper.style.maxHeight = window.innerHeight - distanceFromTop - 180 + 'px';
      }
    });
  }
};

export default { methods };
