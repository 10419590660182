<template>
  <gli-dialog
    :title="isNew ? $t('Add new organization') : $t('Edit organization')"
    :isNew="isNew"
    :editedObject="editedOrganization"
    :deleteDisabled="!isSuperAdmin"
    @delete="deleteOrganization(editedOrganization.id)"
    @save="save"
    @cancel="cancel"
  >
    <v-row>
      <v-col cols="12">
        <gli-text-field
          :disabled="!isSuperAdmin"
          v-model="name"
          :label="$t('Name')"
          required
          :rules="[rules.required]"
          :autofocus="isNew"
        ></gli-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-tabs v-model="tab">
          <v-tab>{{ $t('Raster sources') }}</v-tab>
          <v-tab>{{ $t('Styles') }}</v-tab>
          <v-tab>{{ $t('Tags') }}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <raster-sources></raster-sources>
          </v-tab-item>
          <v-tab-item>
            <styles></styles>
          </v-tab-item>
          <v-tab-item>
            <tags></tags>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </gli-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import rules from '../mixins/rules';
import RasterSources from './RasterSources.vue';
import Styles from './Styles.vue';
import Tags from './Tags.vue';

export default {
  name: 'OrganizationDialog',

  data() {
    return {
      tab: 0
    };
  },
  computed: {
    ...mapGetters('account', ['isSuperAdmin']),
    ...mapGetters('organization', ['isNew', 'editedOrganization']),
    ...mapFields('organization', ['editedOrganization.name'])
  },

  methods: {
    ...mapActions('organization', {
      save: 'save',
      cancel: 'cancel',
      deleteOrganization: 'delete'
    })
  },
  mixins: [rules],
  components: { RasterSources, Styles, Tags }
};
</script>
