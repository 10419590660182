<template>
  <gli-dialog
    width="700"
    :title="isNewRasterSource ? $t('Add new raster source') : $t('Edit raster source')"
    :isNew="isNewRasterSource"
    :editedObject="editedRasterSource"
    @delete="deleteRasterSource(editedRasterSource._id)"
    @save="saveRasterSource"
    @cancel="cancelEditingRasterSource"
  >
    <v-row dense>
      <v-col cols="12">
        <gli-text-field
          v-model="name"
          :label="$t('Name')"
          required
          :rules="[rules.required]"
          :autofocus="isNewRasterSource"
        ></gli-text-field>
      </v-col>
      <v-col cols="12">
        <gli-text-field
          v-model="url"
          :label="$t('Url')"
          required
          :rules="[rules.required]"
        ></gli-text-field>
      </v-col>
      <v-col cols="12">
        <gli-text-field
          :maxLength="200"
          v-model="description"
          :label="$t('Description')"
        ></gli-text-field>
      </v-col>
    </v-row>
  </gli-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import rules from '../mixins/rules';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'RasterSourceDialog',

  data() {
    return {};
  },
  computed: {
    ...mapGetters('organization', ['isNewRasterSource', 'editedRasterSource']),
    ...mapFields('organization', [
      'editedRasterSource.name',
      'editedRasterSource.url',
      'editedRasterSource.description'
    ])
  },

  methods: {
    ...mapActions('organization', [
      'saveRasterSource',
      'cancelEditingRasterSource',
      'deleteRasterSource'
    ])
  },
  mixins: [rules]
};
</script>
