<template>
  <v-container class="pa-0" fluid v-resize="resizeTable">
    <v-card>
      <v-flex>
        <v-card-text>
          <v-data-table
            :mobile-breakpoint="0"
            :headers="headers.map((item) => ({ ...item, text: $t(item.text) }))"
            fixed-header
            :items="editedOrganization.settings.styles"
            item-key="id"
            :options.sync="pagination"
          >
            <template v-slot:item.name="{ value }">
              <span>{{ value }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <gli-editor-button
                    class="ml-2"
                    color="primary"
                    fab
                    x-small
                    dark
                    v-on="on"
                    @click="editStyle(item)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </gli-editor-button>
                </template>
                <span>{{ $t('Edit') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <gli-editor-button
                    class="ml-2"
                    color="error"
                    fab
                    x-small
                    dark
                    v-on="on"
                    @click="deleteStyle(item._id)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </gli-editor-button>
                </template>
                <span>{{ $t('Delete') }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-flex>

      <v-card-actions class="mr-2">
        <v-spacer></v-spacer>
        <gli-editor-button small color="primary" @click="addNewStyle">{{
          $t('New style')
        }}</gli-editor-button>
      </v-card-actions>
    </v-card>

    <style-dialog v-if="editedStyle"></style-dialog>
  </v-container>
</template>

<style lang="scss" scoped></style>
<script>
import StyleDialog from './StyleDialog.vue';
import { mapActions, mapGetters } from 'vuex';
import resizeTable from '../mixins/resizeTable';

export default {
  name: 'Styles',
  props: [],

  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: '',
          value: 'actions',
          width: 232,
          sortable: false,
          align: 'end'
        }
      ],
      pagination: {
        page: 1
      }
    };
  },

  computed: {
    ...mapGetters('organization', ['editedOrganization', 'editedStyle'])
  },

  mounted() {},
  methods: {
    ...mapActions('organization', ['addNewStyle', 'editStyle', 'deleteStyle'])
  },
  mixins: [resizeTable],
  components: { StyleDialog }
};
</script>
