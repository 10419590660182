var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.resizeTable),expression:"resizeTable"}],staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-card',[_c('v-flex',[_c('v-card-text',[_c('v-data-table',{attrs:{"mobile-breakpoint":0,"headers":_vm.headers.map(function (item) { return (Object.assign({}, item, {text: _vm.$t(item.text)})); }),"fixed-header":"","items":_vm.editedOrganization.settings.styles,"item-key":"id","options":_vm.pagination},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(value))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('gli-editor-button',_vm._g({staticClass:"ml-2",attrs:{"color":"primary","fab":"","x-small":"","dark":""},on:{"click":function($event){return _vm.editStyle(item)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Edit')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('gli-editor-button',_vm._g({staticClass:"ml-2",attrs:{"color":"error","fab":"","x-small":"","dark":""},on:{"click":function($event){return _vm.deleteStyle(item._id)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Delete')))])])]}}])})],1)],1),_c('v-card-actions',{staticClass:"mr-2"},[_c('v-spacer'),_c('gli-editor-button',{attrs:{"small":"","color":"primary"},on:{"click":_vm.addNewStyle}},[_vm._v(_vm._s(_vm.$t('New style')))])],1)],1),(_vm.editedStyle)?_c('style-dialog'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }