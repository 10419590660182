<template>
  <gli-dialog
    width="500"
    :title="isNewStyle ? $t('Add new style') : $t('Edit style')"
    :isNew="isNewStyle"
    :editedObject="editedStyle"
    @delete="deleteStyle(editedStyle._id)"
    @save="saveStyle"
    @cancel="cancelEditingStyle"
  >
    <v-row dense>
      <v-col cols="12">
        <gli-text-field
          v-model="name"
          :label="$t('Name')"
          required
          :rules="[rules.required]"
          :autofocus="isNewStyle"
        ></gli-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12"
        ><v-select
          item-text="id"
          item-value="id"
          :value="editedStyle.layers[0]"
          @change="editLayer"
          :items="editedStyle.layers"
          :label="$t('Layers')"
        ></v-select
      ></v-col>
      <v-col cols="12" class="d-flex justify-center">
        <v-color-picker
          :value="{
            r: parseInt(editedLayer.color.slice(1, 3), 16),
            g: parseInt(editedLayer.color.slice(3, 5), 16),
            b: parseInt(editedLayer.color.slice(5, 7), 16),
            a: editedLayer.opacity
          }"
          @update:color="setLayerColor"
          mode="rgba"
        ></v-color-picker>
      </v-col>
    </v-row>
  </gli-dialog>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import rules from '../mixins/rules';
import { mapFields } from 'vuex-map-fields';
import { BaseStyle } from '../types/types';

export default {
  name: 'StyleDialog',

  data() {
    return {
      baseStyleItems: [BaseStyle.Standard, BaseStyle.Dark, BaseStyle.Light]
    };
  },
  computed: {
    ...mapGetters('organization', ['isNewStyle', 'editedStyle', 'editedLayer']),
    ...mapFields('organization', ['editedStyle.name'])
  },

  methods: {
    ...mapActions('organization', ['saveStyle', 'cancelEditingStyle', 'deleteStyle']),
    ...mapMutations('organization', ['setLayerColor', 'editLayer'])
  },
  mixins: [rules]
};
</script>
